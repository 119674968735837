import React from "react"

import Layout from "../components/layout"
import BlogPostsListWithSeo from "../components/common/BlogPostsListWithSeo"

const INDEX_META_TAGS = [
  "javascript", "frontend", "react", "view", "angular", "ecmascript",
  "frontend blog", "javascript blog", "frontend development", "javascript development",
]

const IndexPage = (props) => {
  const { pageContext, path } = props

  return (
    <Layout>
      <BlogPostsListWithSeo
        initialPosts={pageContext.posts}
        totalCount={pageContext.totals}
        path={path}
        metaKeywords={INDEX_META_TAGS}
        metaTitle="Home"
        metaDescription="You're right, it is yet another blog about Javascript development."
      />
    </Layout>
  )
}

export default IndexPage
